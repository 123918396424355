import React from "react";

import "../styles/global.scss"
import "../styles/future.scss"

export const Future = () => {
    return (
        <section id="future">
            <div className="content">
                <h2 className="title future-title">dalsze <br/>plany</h2>
                <div id="future-content">
                    Moim aktualnym celem zawodowym jest rozwój oraz możliwość wykazania się jako Fullstack Developer,
                    podczas mojej kilkuletniej przygody jako programista Python zdobyłem doświadczenie przede wszystkim
                    w zakresie tworzenia backendu, jednakże miałem również okazję pracować nad frontendem. Pragnę nadal
                    rozwijać oba te aspekty web developmentu, stawiając sobie kolejne cele w tym właśnie zakresie. Poza
                    tym pracuję nad rozwinięciem swoich kompetencji poszerzając je o kierunki związane z DevOps, tak aby
                    posiadać pełen wachlarz umiejętności jako programista.
                </div>
            </div>
        </section>
    )
};
