import React from "react"

import "../styles/skills.scss"
import "../styles/global.scss"

const skills_data = [
    {
        name: "Python",
        description: "Django, Django Rest Framework, FastAPI, PonyORM, Pytest, Celery, Scrapy, Poetry"
    },
    {
        name: "PHP",
        description: "Composer, PHPUnit, Phalcon"
    },
    {
        name: "Java",
        description: "JUnit, Gradle, Akka, Hibernate"
    },
    {
        name: "C/C++",
        description: ""
    },
    {
        name: "JavaScript",
        description: "Vue.js, React, jQuery, Gatsby"
    },
    {
        name: "DevOps",
        description: "Ansible, Openstack, JenkinsCI, GitlabCI, ELK, Prometheus, AlertManager, Kubernetes, Keycloak"
    },
    {
        name: "HTML",
        description: ""
    },
    {
        name: "DBMS",
        description: "PostgreSQL, MongoDB, IndexedDB, SQLite, MariaDB"
    },
    {
        name: "OS",
        description: "MacOS, Windows, Linux"
    },
    {
        name: "VCS",
        description: "Git"
    },
    {
        name: "CSS",
        description: "Sass"
    }
];

const SkillWrapper = ({name, description, children}) => {
    return (
        <div className="grid-item">
            <h3 className="row-title">{name}</h3>
            {children}
        </div>
    )
};

export const Skills = () => {
    return (
        <section id="skills">
            <div className="content">
                <h2 className="title skills-title">umiejętności</h2>
                <div className="grid-container skills-container">
                    {skills_data.map((e) => (
                        <SkillWrapper name={e.name}>
                            <div>{e.description}</div>
                        </SkillWrapper>
                    ))}
                </div>
            </div>
        </section>
    )
};
