import React from "react";

import "../styles/global.scss";
import "../styles/education.scss";


const education_data = [
    {
        university: "Uniwersystet Ekonomiczny we Wrocławiu",
        years: "2019 - 2021",
        description: "Zaoczne studia magisterskie na kierunku Informatyka w Biznesie i specjalizacji Big Data."
    },
    {
        university: "Politechnika Wrocławska",
        years: "2014 - 2019",
        description: "Studia inżynierskie na kierunku Informatyka i specjalizacji Inżynieria Internetowa na wydziale " +
            "Elektroniki."
    }
];


const EducationListWrapper = ({university, years, children}) => {
    return (
        <li className="education-row">
            <h3 className="row-title education-row-title">{university}</h3>
            <div className="education-row-subtitle">{years}</div>
            <div className="education-description">{children}</div>
        </li>
    )
};


export const Education = () => {
    return (
        <section id="education">
            <div className="content">
                <h2 className="title education-title">edukacja</h2>
                <ul className="education-content">
                    {education_data.map((e) => (
                        <EducationListWrapper university={e.university} years={e.years}>
                            {e.description}
                        </EducationListWrapper>
                    ))}
                </ul>
            </div>
        </section>
    )
};
