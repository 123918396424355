import React from "react";
import "../styles/hero.scss"
import portrait from "../assets/php-master-edited.png"
import {Navbar} from "./navbar";


export const Hero = () => {
    return (
        <section id="hero">
            <div className="content">
                <Navbar/>
                <div id="hero-content">
                    <h1 className="hero-title">Cześć,
                        <br/>
                        jestem Artur!
                    </h1>
                    <img src={portrait} alt="portrait"/>
                </div>
            </div>
        </section>
    )
};
