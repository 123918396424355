import React from "react"

import "../styles/experience.scss"
import "../styles/global.scss"

const experience_data = [
    {
        company: "Sii",
        position: "Python Developer",
        years: "2020 - Obecnie",
        description: "Projektowanie, rozwój i utrzymanie aplikacji internetowych oraz systemu mikroserwisów w języku Python (Python2, Python3, " +
            "Django, Django Rest Framework, Tornado, Celery, FastApi, Pytest, AsyncIO, Poetry). Utrzymanie systemu serwisów pracujących w chmurze " +
            "Openstack oraz klastrze Kubernetes (Python, FTP, PostgreSQL, MongoDB, Redis, RabbitMQ, Keycloak), monitorowanie środowiska (Prometheus, AlertManager) " +
            "oraz deployment (Ansible, HELM)."
    },
    {
        company: "STX Next",
        position: "Python Developer",
        years: "2020 - 2020",
        description: "Projektowanie, rozwój oraz utrzymanie aplikacji w\n" +
            "technologi Python3 (Django, Django Rest Framework, Pytest, Celery, UML)."
    },
    {
        company: "Nokia Solutions and Networks",
        position: "Software Developer",
        years: "2017 - 2019",
        description: "Projektowanie, rozwój oraz utrzymanie wewnętrznych aplikacji\n" +
            "internetowych, w technologiach JavaScript (Vue.js, jQuery), Python3 (Django, Django Rest Framework), Java\n" +
            "(JUnit, Akka, Hibernate) oraz PHP (PHP5, PHP7, Phalcon Framework). Konfiguracja oraz utrzymanie narzędzi\n" +
            "potrzebnych w procesie wytwarzania oprogramowania i działania aplikacji: bazy danych, Jenkins CI, Docker,\n" +
            "Vagrant, a także maszyny wirtualne w chmurze. Przeprowadzanie rozmów rekrutacyjnych jako osoba\n" +
            "techniczna na stanowisko programisty PHP oraz Python. Prowadzenie laboratoriów z podstaw języka Python\n" +
            "dla studentów Politechniki Wrocławskiej w ramach kursu Praktyczne Aspekty Wytwarzania Oprogramowania."
    },
    {
        company: "Global Logic",
        position: "Junior Software Tester",
        years: "2016 - 2017",
        description: "Automatyzacja procesu testowania inteligentnych urządzeń\n" +
            "domowych, projektowanie oraz implementacja testów funkcjonalnych oraz niefunkcjonalnych we frameworku\n" +
            "Pytest."
    },
];

const ExperienceListWrapper = ({company, position, years, children}) => {
    return (
        <li className="experience-row">
            <h3 className="row-title experience-row-title">{company}</h3>
            <div className="experience-position-subtitle">{position}</div>
            <div className="experience-years-subtitle">{years}</div>
            <div className="experience-description">{children}</div>
        </li>
    )
};

export const Experience = () => {
    return (
        <section id="experience">
            <div className="content">
                <h2 className="title experience-title">doświadczenie</h2>
                <ul className="experience-content">
                    {experience_data.map((e) => (
                        <ExperienceListWrapper company={e.company} position={e.position} years={e.years}>
                            {e.description}
                        </ExperienceListWrapper>
                    ))}
                </ul>
            </div>
        </section>
    )
};
