import React from "react"

import "../styles/navbar.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {faBlog} from "@fortawesome/free-solid-svg-icons";

export const Navbar = () => {
    const github_url = "https://github.com/";
    const linkedin_url = "https://pl.linkedin.com/in/artur-malarz-b8487a143";
    const blog_url = "https://blog.arturmalarz.pl";

    return (
        <div className={"navbar"}>
            <div className={"navbar-title"}>{'<'}Artur Malarz/{'>'}</div>
            <div className={"navigation"}>
                {/*<a className={"navigation-item"} href={github_url}>*/}
                {/*    <FontAwesomeIcon icon={faGithub}/>*/}
                {/*</a>*/}
                <a id={"blog-text"} className={"navigation-item"} href={blog_url}>
                    BLOG
                </a>
                <a id={"blog-icon"} className={"navigation-item"} href={blog_url}>
                    <FontAwesomeIcon title="Blog Artur Malarz" icon={faBlog} />
                </a>
                <a className={"navigation-item"} href={linkedin_url}>
                    <FontAwesomeIcon title="Artur Malarz profil Linkedin" icon={faLinkedin}/>
                </a>
            </div>
        </div>
    )
}
