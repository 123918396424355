import React from "react";

import "../styles/global.scss"
import "../styles/about.scss"

export const About = () => {
    return (
        <section id="about">
            <div className="content">
                <h2 className="title about-title">kim <br/>jestem?</h2>
                <div id="about-content">
                    Cześć, jestem Artur! Interesuje się szeroko pojętym programowaniem,
                    a w szczególności web developmentem. Swoją przygodę z branżą IT
                    rozpocząłem studiując informatykę we Wrocławiu i pracując jako tester automatyczny,
                    a od kilku lat zajmuję się rozwojem i utrzymaniem oprogramowania. Języki programowania
                    z którymi najczęściej pracowałem i które najbardziej mnie interesują
                    to PHP, Python i JavaScript. Nie lubię stać w miejscu, dlatego
                    staram się nadążać za trendami w nowych technologiach, pogłębiając
                    moją wiedzę oraz zdobywajac nowe umiejętności z dziedziny web developmentu.
                    W czasie wolnym od komputera jestem kolarzem szosowym amatorem i
                    piwowarem domowym. Moich piw można spróbować podczas wrocławskiej
                    ligii piwowarów PiwoWarZone, w której biorę udział od kilku edycji.
                </div>
            </div>
        </section>
    )
};
