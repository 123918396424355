import React from "react";

import "../styles/global.scss";
import "../styles/contact.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons";

export const Contact = () => {
    const linkedin_url = "https://pl.linkedin.com/in/artur-malarz-b8487a143";
    return (
        <section id="contact">
            <div className="content">
                <h2 className="title contact-title">kontakt</h2>
                <div id="contact-content">
                    <div>kontakt@arturmalarz.pl</div>
                    <div className={"navigation-contact"}>
                        {/*<a className={"navigation-item"} href={""}>*/}
                        {/*    <FontAwesomeIcon icon={faGithub}/>*/}
                        {/*</a>*/}
                        <a className={"navigation-item"} href={linkedin_url}>
                            <FontAwesomeIcon title="Artur Malarz profil Linkedin" icon={faLinkedin}/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
};
