import React, {useEffect, useState} from "react"

import "../styles/global.scss"

import {Layout} from "../components/layout"
import SEO from "../components/seo"
import {Hero} from "../components/hero"
import {About} from "../components/about"
import {Education} from "../components/education"
import {Contact} from "../components/contact"
import {Experience} from "../components/experience"
import {Future} from "../components/future";
import {Skills} from "../components/skills";


const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Programista - Fullstack Developer" lang="pl" description="Artur Malarz, miłośnik programowania
                w języku Python, Fullstack Python Developer z doświadczeniem w pracy nad aplikacjami internetowymi."/>
            <Hero/>
            <About/>
            <Education/>
            <Experience/>
            <Skills/>
            <Future/>
            <Contact/>
        </Layout>
    )
};

export default IndexPage
